.WAcard {
  // background-image: url("../../../public/assests/bgWa.png");
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  // padding: 20px 10%;
  box-sizing: border-box;
  &.rev {
    // background-image: url("../../../public/assests/bgWaR.png");
    flex-direction: row-reverse;
  }
  // height: 300px;
  // background-color: aqua;
  .WAcard__left {
    flex: 1;
    height: 100%;
    // margin-bottom: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    // background-color: aqua;
    img {
      // height: 100%;
      width: 90%;
    }
  }

  .WAcard__right {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 50%;
    // background-color: green;
    &.pad-r {
      padding-left: 20px;
      box-sizing: border-box;
    }
    h1 {
      font-size: 2rem;
      font-weight: 500;
      // color: rgba(1, 17, 53, 1);
      color: #FFFFFF;
    }
    p {
      font-size: 1.2rem;
      // color: rgb(33, 36, 41);
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}

// .WAcard__right h1:first-child {
//   font-family: 'Poppins';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   color: red!important;
// }

// .WAcard .WAcard__right h1:nth-child(1) {
//   font-family: Poppins;
//   font-size: 14px;
//   font-weight: 400;
//   text-align: left;
//   color: red!important;
// }

@media screen and (min-width:420px) and (max-width:768px) {
  .WAcard {
    // background-image: url("../../../public/assests/bgWa.png");
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    padding: 10px 10px;
    box-sizing: border-box;
  }

  .WAcard__left {
    flex: 1;
    height: 100%;
    // margin-bottom: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 200px;
  }
  .WAcard__right {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 50%;
    // background-color: green;
    &.pad-r {
      padding-left: 20px;
      box-sizing: border-box;
    }
    h1 {
      font-size: 1.5rem;
      font-weight: 500;
      // color: rgba(1, 17, 53, 1);
      color: #FFFFFF;
    }
    p {
      font-size: 1.1rem;
      // color: rgb(33, 36, 41);
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}
@media screen and (max-width:420px) {
  .WAcard {
    // background-image: url("../../../public/assests/bgWa.png");
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    display: flex;
    flex-direction: column!important;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    padding: 10px 10px;
    box-sizing: border-box;
    // background-color: yellow;
  }

  .WAcard__left {
    flex: 1;
    height: 100%;
    // margin-bottom: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 200px;
  }
  .WAcard__right {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%!important;
    margin-top: 20px;
    // background-color: green;
    &.pad-r {
      padding-left: 20px;
      box-sizing: border-box;
    }
    h1 {
      font-size: 1.5rem;
      font-weight: 500;
      // color: rgba(1, 17, 53, 1);
      font-family: "Public Sans";
      color: #FFFFFF;
    }
    p {
      font-size: 1.1rem;
      // color: rgb(33, 36, 41);
      font-weight: 400;
      font-family: "Public Sans";
      color: #FFFFFF;
    }
  }
}