.editprofile-cont {
    display: flex;
}

.right-ep {
    width: 100%;
    background-color: #F1F1F1;
    display: flex;
    flex-direction: column;
}

.ep-header {
    border-bottom: 1px solid #ABC;
    padding: 10px;
    box-sizing: border-box;
}

.ep-header h3 {
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
}

.bottom-rep {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px;
    box-sizing: border-box;
}

.rleft-ep {
    /* width: 100%; */
    width: 250px;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.rleft-ep-card {
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.rleft-ep-card button {
    font-family: Roboto;
    text-align: center;
    width: 100%;
    height: 50px;
    color: #212429;
    border-radius: 10px;
    background-color: #F1F1F1;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}

.rleft-ep-card button:hover {
    opacity: 0.7;
}

.tab-button.active {
    color: #FFF;
    background-color: #011135;
}

.rright-ep {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.rright-ep-card {
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.rright-ep-top-btn-cont {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.rright-ep-top-btn-cont h3 {
    color: #212429;
    font-family: Public Sans;
    font-size: 1.2rem;
    font-weight: 500;
}

.rright-ep-top-btn-cont button {
    font-family: Roboto;
    text-align: center;
    width: 160px;
    height: 50px;
    color: #FFF;
    border-radius: 10px;
    background-color: #011135;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}

.rright-ep-top-btn-cont button:hover {
    opacity: 0.7;
}

.rmform-single {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
    width: 100%;
}

.rmform-single label {
    color: var(--080808, #0F0F0F);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
}

.rmform-single input {
    border-radius: 10px;
    border: 1px solid rgba(166, 169, 172, 0.70);
    height: 40px;
    padding: 0 10px;
    outline: none;
    box-sizing: border-box;
    color: var(--080808, #0F0F0F);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    margin-top: 0;
    width: 100%;
}

.rmform-two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.rmform-two label {
    color: var(--080808, #0F0F0F);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    width: 48%;
    text-align: start;
}

.rmform-two input {
    border-radius: 10px;
    border: 1px solid rgba(166, 169, 172, 0.70);
    height: 40px;
    padding: 0 10px;
    outline: none;
    box-sizing: border-box;
    color: var(--080808, #0F0F0F);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    margin-top: 0;
    width: 100%;
}

.rmform-three {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.rmform-three label {
    color: var(--080808, #0F0F0F);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    width: 32%;
    text-align: start;
}

.rmform-three input {
    border-radius: 10px;
    border: 1px solid rgba(166, 169, 172, 0.70);
    height: 40px;
    padding: 0 10px;
    outline: none;
    box-sizing: border-box;
    color: var(--080808, #0F0F0F);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    margin-top: 0;
    width: 100%;
}

.rmform-remark {
    color: var(--080808, #0F0F0F);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;

}

.rmform-remark textarea {
    border-radius: 10px;
    border: 1px solid rgba(166, 169, 172, 0.70);
    height: 80px;
    padding: 0 10px;
    outline: none;
    box-sizing: border-box;
    color: var(--080808, #0F0F0F);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    margin-top: 0;
    width: 100%;
}


.rmform-btn-cont {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}



@media screen and (min-width: 480px) and (max-width: 1024px) {

    .editprofile-cont {
        display: flex;
        flex-direction: column;
    }

    .bottom-rep {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        box-sizing: border-box;
    }

    .rleft-ep {
        width: 100%;
        max-width: none;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
    }

    .rleft-ep-card {
        background-color: #FFF;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        gap: 10px;
        padding: 10px;
        box-sizing: border-box;
    }

    .rleft-ep-card button {
        font-family: Roboto;
        text-align: center;
        /* width: 120px; */
        height: 50px;
        color: #212429;
        border-radius: 10px;
        background-color: #F1F1F1;
        /* background-color: transparent; */
        border: none;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        width: 100%;
    }


}


@media screen and (max-width: 480px) {

    .editprofile-cont {
        display: flex;
        flex-direction: column;
    }

    .bottom-rep {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        box-sizing: border-box;
    }

    .rleft-ep {
        width: 100%;
        max-width: none;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
    }

    .rleft-ep-card {
        background-color: #FFF;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        gap: 10px;
        padding: 10px;
        box-sizing: border-box;
    }

    .rleft-ep-card button {
        font-family: Roboto;
        text-align: center;
        /* width: 120px; */
        height: 50px;
        color: #212429;
        border-radius: 10px;
        background-color: #F1F1F1;
        /* background-color: transparent; */
        border: none;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        width: 100%;
    }

    .rmform-two {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0px;
        gap: 10px;
        width: 100%;
    }
    
    .rmform-two label {
        color: var(--080808, #0F0F0F);
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 0;
        width: 100%;
        text-align: start;
    }
}