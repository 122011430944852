.WhyAudeet {
  display: flex;
  flex-direction: column;
  // margin-top: 50px;
  background-color: #DCF5FF;
  padding: 5% 0;
  box-sizing: border-box;
  .WA__top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 50px;
    padding: 0 5%;
  box-sizing: border-box;
    h1 {
      font-size: 2.5rem;
      font-weight: 500;
      color: rgba(1, 17, 53, 1);
      padding-left: 10px;
      box-sizing: border-box;
      text-align: start;
      span {
        color: rgba(0, 144, 189, 1);
      }
    }
  }
  .WA__bottom {
    display: flex;
    flex-direction: column;
    background-image: url("../../../public/assests/why audeet.png");
   background-position: top;
   background-repeat: no-repeat;
   background-size: cover;
   padding: 0 5%;
  box-sizing: border-box;
  }
}
