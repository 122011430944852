.Benifits {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  // margin-top: 10%;
  background-color: #DCF5FF;
  padding: 5%;
  box-sizing: border-box;
  .top {
    display: flex;
    justify-content: flex-start;
    h1 {
      font-weight: 500;
      margin-bottom: 120px;
      text-align: start;
      width: 100%;
      max-width: 500px;
      span {
        color: rgba(0, 144, 189, 1);
      }
    }
  }
  .bottom {
    display: flex;
    // min-height: 60vh;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    // width: calc(100% - 40px);
    width: 100%;
    background-color: rgba(0, 144, 189, 0.39);
    margin: 0 auto;
    border-radius: 20px;
    background-image: url("../../../public/assests/Ellipse 98.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    .left-cards {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 100px;

      .bottom__card {
        display: flex;
        // flex-direction: column;
        align-items: center;
        gap: 10px;
        border-radius: 20px;
        // box-shadow: 4px 4px 23px 0px rgba(0, 0, 0, 0.06);
        max-width: 300px;

        img {
          width: 25%;
        }
        h5 {
          color: #FFFFFF;
          font-size: 0.9rem;
          margin: 0;
          margin-bottom: 6px;
        }
        p {
          width: 100%;
          text-align: start;
          margin-bottom: 5px;
          color: #FFFFFF;
          font-size: 0.8rem;
        }
      }
    }
    img {
      height: 80%;
    }
    .right-cards {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 90px;
      // padding-bottom: 150px;
      .bottom__card {
        display: flex;
        // flex-direction: column;
        align-items: center;
        gap: 10px;
        border-radius: 20px;
        // box-shadow: 4px 4px 23px 0px rgba(0, 0, 0, 0.06);
        max-width: 300px;

        img {
          width: 25%;
          // border-radius: 50%;
        }
        h5 {
          color: #FFFFFF;
          font-size: 0.9rem;
          margin-bottom: 6px;
        }
        p {
          width: 100%;
          text-align: start;
          margin-bottom: 5px;
          color: #FFFFFF;
          font-size: 0.8rem;
        }
      }
    }
  }
}

.benifits-center-image img{
  width: 100%;
  max-width: 300px;
  transform: translateY(-100px);
}


@media screen and (max-width:768px) {
  .Benifits {
    display: flex;
    flex-direction: column;
    // height: 100vh;
    // margin-top: 10%;
    background-color: #DCF5FF;
    padding: 16px;
    box-sizing: border-box;
    .top {
      display: flex;
      justify-content: flex-start;
      h1 {
        font-weight: 500;
        margin-bottom: 16px;
        text-align: start;
        span {
          color: rgba(0, 144, 189, 1);
        }
      }
    }
    .bottom {
      display: flex;
      flex-direction: column;
      // min-height: 60vh;
      align-items: center;
      justify-content: space-around;
      gap: 10px;
      // width: calc(100% - 40px);
      width: 100%;
      background-color: rgba(0, 144, 189, 0.39);
      margin: 0 auto;
      border-radius: 20px;
      padding: 16px;
      box-sizing: border-box;
      .left-cards {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 16px;
  
        .bottom__card {
          display: flex;
          // flex-direction: column;
          align-items: center;
          gap: 10px;
          border-radius: 20px;
          // box-shadow: 4px 4px 23px 0px rgba(0, 0, 0, 0.06);
          max-width: 300px;
  
          img {
            width: 25%;
          }
          h5 {
            color: #FFFFFF;
            font-size: 0.9rem;
            margin: 0;
            margin-bottom: 6px;
          }
          p {
            width: 100%;
            text-align: start;
            margin-bottom: 5px;
            color: #FFFFFF;
            font-size: 0.8rem;
          }
        }
      }
      img {
        height: 80%;
      }
      .right-cards {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 16px;
        .bottom__card {
          display: flex;
          // flex-direction: column;
          align-items: center;
          gap: 10px;
          border-radius: 20px;
          // box-shadow: 4px 4px 23px 0px rgba(0, 0, 0, 0.06);
          max-width: 300px;
  
          img {
            width: 25%;
            // border-radius: 50%;
          }
          h5 {
            color: #FFFFFF;
            font-size: 0.9rem;
            margin-bottom: 6px;
          }
          p {
            width: 100%;
            text-align: start;
            margin-bottom: 5px;
            color: #FFFFFF;
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .benifits-center-image img{
    width: 100%;
    max-width: 300px;
    transform: translateY(0);
  }

}