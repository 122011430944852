.offer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #DCF5FF;
    padding: 5%;
    box-sizing: border-box;
    background-image: url("../../../public/assests/Ellipse 108.png");
    background-position: left;
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-size: 30%;
}

.offer-heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0;
    padding-bottom: 24px;
    box-sizing: border-box;
}

.offer-heading h3 {
    color: #000510;
    font-family: Public Sans;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 97%;
    text-align: start;
}

.offer-heading h3 span {
    color: var(--Purple, #0090BD);
    font-family: Public Sans;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 97%;
}

.offer-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10%;
    width: 100%;
}

.offer-card {
    background-color: #D3EAFF;
    border-radius: 12px;
    padding: 16px;
    box-sizing: border-box;
    max-width: 250px;
    min-height: 200px;
    margin-bottom: 24px;
}

.offer-card h1 {
    color: #0090BD;
    font-size: 2rem;
}

.offer-card h4 {
    color: #363737;
    font-size: 1.6rem;
    font-weight: 600;
    font-family: Inter;
    text-align: center;
}