.register-container {
    // background-image: url("../../../public/assests/Frame.png");
    // background-image: url("../../../assests/login.png");
    // background-position: center;
    // // background-repeat: no-repeat;
    // background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;

    // align-items: center;
    // justify-content: center;
    // gap: 10px;
    .left-part {
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-bottom: 120px;
        padding: 60px;
        color: white;
        // width: 440px;
        width: 100%;
        // max-width: 500px;
        text-align: center;
        background-image: url("../../../public/assests/login.png");
        background-position: center;
        // background-repeat: no-repeat;
        background-size: cover;
    }

    /* Login.scss */
    .right-part {
        display: flex;
        // background-color: #ccc;
        // justify-content: center;
        align-items: center;
        // width: 800px;
        width: 100%;

        .register-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // margin-left: 30%;
            background: white;
            // width: 420px;
            width: 100%;

            h2 {
                margin-bottom: 16px;
                font-size: 1rem;
                font-weight: 400;
            }

            input {
                // padding: 10px 53px;
                margin-bottom: 15px;
                // width: 200px;
                border: 1px solid #ccc;
                border-radius: 4px;
                font-size: 16px;
            }

            button {
                padding: 12px 34px;
                background-color: #011135;
                color: #fff;
                border: none;
                border-radius: 4px;
                font-size: 16px;
                width: 100%;
                cursor: pointer;
            }
            button:hover {
              opacity: 0.8;
            }
        }
    }
}


.register-form {
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
    min-width: 300px;
}


.form_input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.form_input label {
    color: #657597;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.4px;
}

.form_input input {
    // padding: 12px 0 12px 12px;
    border-radius: 10px;
    border: 1px solid rgba(166, 169, 172, 0.70);
    color: #657597;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.4px;
    outline: none;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 5px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 8px;
}



.hnl-input-passicon-cont {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    // margin: 0;
    justify-content: flex-end;

}

.hnl-input-passicon-cont input {
    width: 100%;
    // padding: 12px 12px 12px 12px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}


.hn-showpass {
    /* transform: translateX(-30px); */
    position: absolute;
    margin-right: 18px;
    margin-bottom: 16px;
    font-size: 1rem;
    color: #313638;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-link {
    margin-top: 12px;
    text-align: center;
}


@media screen and (max-width:780px) {
    .register-container {
        background-image: none;
        background-position: none;
        // background-repeat: no-repeat;
        background-size: none;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .left-part {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        padding: 0;
        color: white;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        text-align: center;
        display: none;
        max-width: 100%;
    }

    .right-part {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .register-form {
        width: 80%;
        padding: 20px;
        box-sizing: border-box;
        min-width: 200px;
    }

}