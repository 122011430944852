.customer-cont {
    display: flex;
}

.right-customer {
    width: 100%;
}

.rcust-home-cont {
    width: 100%;
}

.rcust-home-img-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.rcust-home-img-cont img {
    width: 40%;
    min-width: 200px;
}

.rcust-home-text-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
}

.rcust-home-text-cont h1 {
    color: #111111;
    font-size: 2rem;
    font-weight: 500;
    font-family: Roboto;
}

.rcust-home-text-cont h3 {
    color: #898989;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: Roboto;
}

.rcust-home-text-cont button {
    border-radius: 10px;
    background: #011135;
    display: flex;
    width: 150px;
    height: 50px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
}

.rcust-home-text-cont button:hover {
    opacity: 0.7;
}


/* customer popup */

.custform-single-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    gap: 5px;
}

.custform-single-cont label {
    display: flex;
    flex-direction: column;
    color: #898989;
    font-family: Public Sans;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    width: 100%;
}

.custform-single-cont input {
    border-radius: 10px;
    border: 1px solid #F1F1F1;
    background: #FFF;
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    outline: none;
    height: 45px;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
}

.custform-bott-popup-cont {
    display: flex;
    flex-direction: column;
    background-color: #ECF5FF;
    border-radius: 10px;
    padding: 12px;
    box-sizing: border-box;
    margin-top: 10px;
}

.custform-bott-popup-cont input[type="radio"] {
    width: 20px;
    height: 20px;
}

.custform-bott-popup-cont input[type="radio"]:checked {
    background-color: #011135;
    accent-color: #011135;
}

.custform-bott-popup-cont label {
    display: flex;
    color: #212429;
    font-family: Public Sans;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    gap: 5px;
    margin-bottom: 5px;
}

.custform-bott-popup-cont input {
    border-bottom: 1px solid #ACB5BD;
    border-top: none;
    border-right: none;
    border-left: none;
    background: transparent;
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    outline: none;
    height: 45px;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
}

.custform-single-cont button {
    font-family: Public Sans;
    text-align: center;
    width: 100%;
    height: 50px;
    color: #FFF;
    border-radius: 10px;
    background-color: #011135;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}

.custform-single-cont button:hover {
    opacity: 0.7;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .customer-cont {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {

    .customer-cont {
        display: flex;
        flex-direction: column;
    }

    .rcust-home-img-cont img {
        width: 90%;
        min-width: 200px;
    }
}