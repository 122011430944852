.future-cont {
    background-color: #011135;
    display: flex;
}

.left-future {
    display: flex;
    flex-direction: column;
    padding: 5%;
    box-sizing: border-box;
    /* justify-content: space-between; */
    width: 50%;
}

.ltop-future {
    width: 100%;
}

.ltop-future h3 {
    color: #FFFFFF;
    font-family: Public Sans;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 97%;
}

.ltop-future h3 span {
    color: var(--Purple, #0090BD);
    font-family: Public Sans;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 97%;
}

.ltop-future h5 {
    color: #FFFFFF;
    font-family: Public Sans;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.ltop-future-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.ltop-future-list span {
    color: #FFFFFF;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.future-checkbox {
    background-color: #0090BD;
    padding: 8px 10px;
    box-sizing: border-box;
    border: none;
    color: #FFF;
    border-radius: 5px;
    font-size: 1.2rem;
    margin-right: 10px;
}

.right-future {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    box-sizing: border-box;
}

.right-future img {
    width: 80%;
}

@media screen and (max-width: 768px) {
    .future-cont {
        background-color: #011135;
        display: flex;
        flex-direction: column;
    }

    .left-future {
        display: flex;
        flex-direction: column;
        padding: 16px;
        box-sizing: border-box;
        /* justify-content: space-between; */
        width: 100%;
    }

    .right-future {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right-future img {
        width: 80%;
    }
}