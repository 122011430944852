.createinvoice-cont {
    display: flex;
    background-color: #F7F9FF;
    width: 100%;
}

.rci-header {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #ACB5BD;
    display: flex;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-weight: 500;
}

.right-ci {
    width: 100%;
}

.rci {
    padding: 16px;
    box-sizing: border-box;
    width: 100%;
}

.top-rci {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    gap: 10px;
}

.topleft-rci {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.single-rci {
    background-color: #FFF;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
}

.single-rci-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.single-rci-row span {
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
}

.single-rci-row input {
    display: flex;
    padding: 6px 8px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(166, 169, 172, 0.70);
    outline: none;
    color: var(--101010, #101010);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
}

.single-rci-row button {
    border: none;
    color: #898989;
    background-color: transparent;
    font-family: Public Sans;
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

/* select and option */
.srci-select-label-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    font-family: Public Sans;
}

.srci-select-label-flex select {
    border-radius: 10px;
    border: 1px solid #898989;
    background: #FFF;
    color: var(--080808, #0F0F0F);
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    outline: none;
    height: 45px;
    /* margin-bottom: 15px; */
    width: 70%;

}

/* Style for the dropdown arrow */
.srci-select-label-flex select:after {
    content: '\25BC';
    /* Unicode character for down arrow */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

/* Style for each option in the dropdown */
.srci-select-label-flex select option {
    padding: 40px;
}

.topright-rci {
    width: 50%;
}

/* select and option */
.srci-select-label-cloumn {
    display: flex;
    flex-direction: column;
    font-family: Public Sans;
}

.srci-select-label-cloumn select {
    border-radius: 10px;
    border: 1px solid rgba(166, 169, 172, 0.70);
    background: #FFF;
    color: var(--080808, #0F0F0F);
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    outline: none;
    height: 45px;
    /* margin-bottom: 15px; */
    width: 100%;

}

/* Style for the dropdown arrow */
.srci-select-label-cloumn select:after {
    content: '\25BC';
    /* Unicode character for down arrow */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

/* Style for each option in the dropdown */
.srci-select-label-cloumn select option {
    padding: 40px;
}

.single-rci-label {
    display: flex;
    flex-direction: column;
    color: var(--101010, #101010);
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
}

.single-rci-label input {
    display: flex;
    width: 100%;
    height: 45px;
    padding-left: 10px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(166, 169, 172, 0.70);
    outline: none;
    color: var(--101010, #101010);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
}

.mid-rci {
    width: 100%;
    margin-bottom: 10px;
}

.rdi-product-heading {
    display: flex;
    flex-direction: column;
    color: #212429;
    font-family: Roboto;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
}

.rci-prod-field {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.rci-prod-field label {
    display: flex;
    flex-direction: column;
    color: var(--101010, #101010);
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
    width: 20%;
}

.rci-prod-field label input {
    display: flex;
    width: 100%;
    height: 45px;
    padding-left: 10px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(166, 169, 172, 0.70);
    outline: none;
    color: var(--101010, #101010);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
}

/* select and option */
.rci-prod-field select {
    border-radius: 10px;
    border: 1px solid rgba(166, 169, 172, 0.70);
    background: #FFF;
    color: var(--080808, #0F0F0F);
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    outline: none;
    height: 45px;
    /* margin-bottom: 15px; */
    width: 100%;
}

.rci-add-more-pro-btn {
    color: #0090BD;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin: 8px 0;
}


.bottom-rci {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    gap: 10px;
}

/* bottom left */
.bl-rci-cont {
    width: 50%;
}

.bleft-rci-single-cont {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F1F1F1;
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
}

.bleft-rci-single-cont span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
}

.bleft-rci-single-cont span button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #898989;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
}

.brci-radio-cont {
    display: flex;
    gap: 10px;
}

.brci-radio-label {
    display: flex;
    align-items: center;
    gap: 2px;
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
}

.brci-radio-label input[type="radio"] {
    width: 20px;
    height: 20px;
}

.brci-radio-label input[type="radio"]:checked {
    background-color: #011135;
    accent-color: #011135;
}

.brci-pay-type-cont {
    display: flex;
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
}

/* select and option */
.brci-pay-type-cont select {
    border-radius: 10px;
    border: 1px solid rgba(166, 169, 172, 0.70);
    background: #FFF;
    color: var(--080808, #0F0F0F);
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    outline: none;
    height: 45px;
    /* margin-bottom: 15px; */
    /* width: 100%; */

}

/* Style for the dropdown arrow */
.brci-pay-type-cont select:after {
    content: '\25BC';
    /* Unicode character for down arrow */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

/* Style for each option in the dropdown */
.brci-pay-type-cont select option {
    padding: 40px;
}


.rci-tandc-cont {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
}

.rci-tandc-cont h5 {
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
}

.rci-tandc-cont img {
    width: 25px;
}

.rci-tandc-list {
    color: #898989;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    /* padding: 10px; */
    box-sizing: border-box;
}

/* bottom right */
.br-rci-cont {
    width: 50%;
}

.br-rci-top-row {
    width: 100%;
    padding: 15px 10px;
    box-sizing: border-box;
}

.br-rci-top-row label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.br-rci-top-row label span {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #0090BD;
    font-weight: 500;
    gap: 5px;
}

.br-rci-top-row label span input[type="checkbox"] {
    width: 20px;
    height: 20px;
}

.br-rci-top-row label span input[type="checkbox"]:checked {
    background-color: #011135;
    accent-color: #011135;
}

.br-rci-second-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #111111;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    padding: 15px 10px;
    box-sizing: border-box;
    border-top: 1px solid #F1F1F1;
}

.br-rci-last-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFD28F;
    border-radius: 10px;
    color: #111111;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    padding: 15px 10px;
    box-sizing: border-box;
}

/* bottom button */
.ci-btn-cont {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #FFF;
    margin: 10px 0;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 15px;
}

.ci-btn-cont button {
    font-family: Public Sans;
    text-align: center;
    width: 160px;
    height: 50px;
    color: #FFF;
    border-radius: 10px;
    background-color: #011135;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}

.ci-btn-cont button:hover {
    opacity: 0.7;
}




/* discount popup */
.ci-dis-popup-input-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    gap: 5px;
}

.ci-dis-popup-input-cont label {
    display: flex;
    flex-direction: column;
    color: #212429;
    font-family: Public Sans;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    width: 100%;
}

.ci-dis-popup-input-cont input {
    border-radius: 10px;
    border: 1px solid var(--a-6-a-9-ac, rgba(166, 169, 172, 0.70));
    background: #FFF;
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    outline: none;
    height: 45px;
    width: 100%;
    margin-bottom: 15px;
    padding-left: 10px;
    box-sizing: border-box;
}

.ci-dis-popup-btn {
    font-family: Public Sans;
    text-align: center;
    width: 100%;
    height: 50px;
    color: #FFF;
    border-radius: 10px;
    background-color: #011135;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}

.ci-dis-popup-btn:hover {
    opacity: 0.7;
}


/* product popup */
.ci-addpro-popup-btn-cont button {
    font-family: Public Sans;
    text-align: start;
    width: 100%;
    height: 50px;
    color: #212429;
    background-color: transparent;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}

.ci-addpro-popup-input-cont label {
    display: flex;
    flex-direction: column;
    color: #212429;
    font-family: Public Sans;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    width: 100%;
}

.ci-addpro-popup-input-cont input {
    border-radius: 10px;
    border: 1px solid var(--a-6-a-9-ac, rgba(166, 169, 172, 0.70));
    background: #FFF;
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    outline: none;
    height: 45px;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
}




/* add from list popup */
/* search container styles */
/* search input  */
.afl-pop-search-box {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
}

.afl-pop-search-box {
    margin-bottom: 10px;
    color: #898989;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    text-align: start;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    background: #FFF;
    padding: 0 10px;
    position: relative;
    box-sizing: border-box;
    border-bottom: 1px solid #F1F1F1;
    border-top: none;
    border-left: none;
    border-right: none;
}

.afl-pop-search-box input {
    border-radius: 14px;
    background: #FFF;
    height: 45px;
    padding: 0 10px;
    outline: none;
    box-sizing: border-box;
    color: #898989;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    margin-top: 0;
    width: 100%;
    border-bottom: 1px solid #F1F1F1;
    border-top: none;
    border-left: none;
    border-right: none;
}

.afl-pop-search-box span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #898989;
    position: relative;
}


.ci-afl-popup-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid #F1F1F1;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 4px;
}

.ci-afl-popup-card div {
    display: flex;
    gap: 5px;
}

.ci-afl-popup-card div div {
    display: flex;
    flex-direction: column;
}

.ci-afl-popup-card button {
    background-color: #D9D9D9;
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 10px;
}

.ci-afl-popup-card h5 {
    color: #212429;
    font-family: Public Sans;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    margin: 0;
}

.ci-afl-popup-card h6 {
    color: #898989;
    font-family: Public Sans;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    margin: 0;
}

.ci-afl-popup-card span {
    color: #212429;
    font-family: Public Sans;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
}




/* add new customer popup */

.ci-pop-anc-single {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    gap: 5px;
}

.ci-pop-anc-single label {
    display: flex;
    flex-direction: column;
    color: #898989;
    font-family: Public Sans;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    width: 100%;
}

.ci-pop-anc-single input {
    border-radius: 10px;
    border: 1px solid #F1F1F1;
    background: #FFF;
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    outline: none;
    height: 45px;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
}

.ci-pop-anc-radio {
    display: flex;
    flex-direction: column;
    background-color: #ECF5FF;
    border-radius: 10px;
    padding: 12px;
    box-sizing: border-box;
    margin-top: 10px;
}

.ci-pop-anc-radio input[type="radio"] {
    width: 20px;
    height: 20px;
}

.ci-pop-anc-radio input[type="radio"]:checked {
    background-color: #011135;
    accent-color: #011135;
}

.ci-pop-anc-radio label {
    display: flex;
    color: #212429;
    font-family: Public Sans;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    gap: 5px;
    margin-bottom: 5px;
}

.ci-pop-anc-radio input {
    border-bottom: 1px solid #ACB5BD;
    border-top: none;
    border-right: none;
    border-left: none;
    background: transparent;
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    outline: none;
    height: 45px;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
}

.ci-pop-anc-single button {
    font-family: Public Sans;
    text-align: center;
    width: 100%;
    height: 50px;
    color: #FFF;
    border-radius: 10px;
    background-color: #011135;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}

.ci-pop-anc-single button:hover {
    opacity: 0.7;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .createinvoice-cont {
        display: flex;
        flex-direction: column;
        background-color: #F7F9FF;
        width: 100%;
    }
}

@media screen and (min-width: 567px) and (max-width: 768px) {
    .createinvoice-cont {
        display: flex;
        flex-direction: column;
        background-color: #F7F9FF;
        width: 100%;
    }

    .rci-prod-field {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 10px;
    }

    .rci-prod-field label {
        display: flex;
        flex-direction: column;
        color: var(--101010, #101010);
        font-family: Public Sans;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 10px;
        width: 32%;
    }
}

@media screen and (max-width: 567px) {
    .createinvoice-cont {
        display: flex;
        flex-direction: column;
        background-color: #F7F9FF;
        width: 100%;
    }

    .rci {
        padding: 12px;
        box-sizing: border-box;
        width: 100%;
    }

    .top-rci {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        gap: 10px;
    }

    .topleft-rci {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .topright-rci {
        width: 100%;
    }

    .rci-prod-field {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 10px;
    }

    .rci-prod-field label {
        display: flex;
        flex-direction: column;
        color: var(--101010, #101010);
        font-family: Public Sans;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        margin: 0;
        width: 100%;
    }

    .bottom-rci {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }

    /* bottom left */
    .bl-rci-cont {
        width: 100%;
    }

    /* bottom right */
    .br-rci-cont {
        width: 100%;
    }
}