.navbar {
  display: flex;
  align-items: center;
  background-color: #DCF5FF;
  padding: 10px 0;
  box-sizing: border-box;
  .left {
    flex: 1;
    padding-left: 5%;
    padding-top: 5px;
    box-sizing: border-box;
    img {
      width: 170px;
      height: 60px;
    }
  }
  .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 7%;
    font-weight: 400;
    padding-right: 5%;
    box-sizing: border-box;
    .nav-btn {
      color: #FFF;
      border: none;
      padding: 10px;
      background-color: rgba(1, 17, 53, 1);
      // background-color: #0090BD;
      border-radius: 5px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
}

.nav-fabar{
 display: none;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-size: 1.2rem;
}

@media screen and (max-width:768px) {
  .nav-opt{
    display: none;
  }
  .nav-btn{
    display: none;
  }

  .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end!important;
    gap: 0;
    font-weight: 400;
    padding-right: 20px;
  }
  .nav-fabar{
    display: block;
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
  }

  .nav-btn-cont-mob {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.nav-btn-cont-mob button {
    width: 100%;
    text-align: start;
    padding: 10px 10px;
    box-sizing: border-box;
    color: #3b3b3b;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #3b3b3b;
}
}
