.downloadinvoice {
    display: flex;
}

.right-di {
    width: 100%;
}

.ldowninvc-header {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #ACB5BD;
    display: flex;
    align-items: center;
    padding-left: 10px;
    box-sizing: border-box;
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-weight: 500;
}

.rbottom-di {
    display: flex;
    width: 100%;
}

.bdowninvc-pdf {
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
}

.br-di-cont {
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
}

.bdowninvc-pdf button {
    border-radius: 10px;
    background: #111111;
    display: flex;
    width: 100%;
    height: 80px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
}

/* share and more option btn */
.rdi-btn-cont {
    display: flex;
    gap: 5px;
    /* margin-top: 10px; */
}

.rdi-share-btn {
    border-radius: 10px;
    background: #ACB5BD;
    color: #212429;
    display: flex;
    width: 50%;
    height: 50px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    text-align: center;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
}

.rdi-share-btn:hover {
    opacity: 0.7;
}

.rdi-more-btn {
    border-radius: 10px;
    background: #011135;
    color: #FFF;
    display: flex;
    width: 50%;
    height: 50px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    text-align: center;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
}

.rdi-more-btn:hover {
    opacity: 0.7;
}

.invoice-pdf {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85vh;
}

/* popup */
.di-pop-share-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.di-pop-share-btn button {
    display: flex;
    flex-direction: column;
    background: transparent;
    color: #212429;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
}

.di-pop-share-btn button img {
    height: 40px;
    width: 40px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .downloadinvoice {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .downloadinvoice {
        display: flex;
        flex-direction: column;
    }

    .rbottom-di {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .bdowninvc-pdf {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }
    
    .br-di-cont {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }
}