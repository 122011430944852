.suppliercard-cont {
    display: flex;
    width: 100%;
    height: 100%;
}

/* left part */
.left-sc {
    width: 50%;
    height: 100%;
}

.left-sc-cont {
    width: 100%;
    background-color: #FFF;
}

/* search container styles */
/* search input  */
.lsc-search-cont {
    padding: 10px;
    box-sizing: border-box;
}

.lsc-search-box {
    color: var(--080808, #0F0F0F);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    text-align: start;
    height: 45px;
    display: flex;
    align-items: center;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 1px 4px 15.2px 0px rgba(0, 0, 0, 0.09);
    width: 100%;
    padding: 0 10px;
    position: relative;
    box-sizing: border-box;
}

.lsc-search-box input {
    border-radius: 14px;
    background: #FFF;
    /* box-shadow: 1px 4px 15.2px 0px rgba(0, 0, 0, 0.09); */
    height: 45px;
    padding: 0 10px;
    outline: none;
    box-sizing: border-box;
    color: var(--080808, #0F0F0F);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    margin-top: 0;
    width: 100%;
    border: none;
}

.lsc-search-box span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666262;
    position: relative;
}

/* single customer container */
.lsup-card-loop {
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    height: 65vh;
}

.lsup-card {
    display: flex;
    justify-content: space-between;
    border: 1px solid #F1F1F1;
    border-radius: 10px;

}

.leftsup-card {
    display: flex;
    padding: 5px;
    box-sizing: border-box;
}

.left-sc-logo {
    color: #111111;
    font-size: 1rem;
    font-weight: 500;
    font-family: Roboto;
    background-color: #FFD28F;
    border-radius: 10px;
    height: 40px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.lsc-edit-btn {
    border: none;
    background-color: transparent;
    font-size: 0.8rem;
    color: #898989;
    font-weight: 500;
    font-family: Roboto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.lsc-lp-cont {
    padding-left: 5px;
    box-sizing: border-box;
}

.lsc-lp-cont h6 {
    color: #111111;
    font-size: 1rem;
    font-weight: 500;
    font-family: Roboto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
}

.lsc-lp-cont p {
    color: #ACB5BD;
    font-size: 0.9rem;
    font-weight: 400;
    font-family: Roboto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
    gap: 2px;
}


.left-right-sc-card {
    display: flex;
    flex-direction: column;
}

.lsc-supplier-text-cont span {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: #011135;
    border-radius: 0 10px 0 10px;
    color: #FFF;
    font-size: 0.9rem;
    font-weight: 400;
    font-family: Roboto;
}

.lsc-sup-price-cont {
    /* background-color: pink; */
    display: flex;
    gap: 5px;
    padding: 5px;
    box-sizing: border-box;
}

.lsc-sup-price-cont button {
    color: #F03D3E;
    font-size: 1rem;
    font-weight: 500;
    font-family: Roboto;
    background-color: transparent;
    border: none;
    border-radius: 4px;
}

.lsupc-add-supplier-btn {
    display: flex;
    justify-content: flex-end;
    padding: 12px;
    box-sizing: border-box;
}

.lsupc-add-supplier-btn button {
    border-radius: 10px;
    background: #011135;
    display: flex;
    width: 120px;
    height: 44px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
}

.lsupc-add-supplier-btn button:hover {
    opacity: 0.7;
}

/* right container */
.right-sc {
    width: 50%;
    height: 100%;
    height: 100vh;
    display: flex;
    background-color: #F1F1F1;
}

.rsc-cont {
    width: 100%;
    height: 100%;
    background-color: #F1F1F1;
    padding: 15px 10px;
    box-sizing: border-box;
}

/* right top container */
.top-rsc {
    margin-bottom: 10px;
    background-color: #FFF;
    border-radius: 10px;
}

.top-rsc-cont {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
}

.tleft-rsc-cont {
    display: flex;
    gap: 5px;
    padding: 5px;
    box-sizing: border-box;
}

.tleft-rsc-cont h5 {
    color: #111111;
    font-size: 1.5rem;
    font-weight: 500;
    font-family: Roboto;
    background-color: #FFD28F;
    border-radius: 10px;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.tleft-rsc-cont span {
    color: #111111;
    font-size: 1rem;
    font-weight: 500;
    font-family: Roboto;

}

.tright-rsc-cont {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}

.tright-rsc-cont button {
    background: transparent;
    /* flex-shrink: 0; */
    color: #898989;
    text-align: center;
    font-family: Roboto;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    gap: 4px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.bottom-trsc-cont {
    display: flex;
    width: 100%;
    padding: 15px 10px;
    box-sizing: border-box;
    border-top: 1px solid #F1F1F1;
}

.bottom-trsc-cont div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.bottom-trsc-cont div h6 {
    color: #212429;
    font-size: 1rem;
    font-weight: 500;
    font-family: Public Sans;
}

.bottom-trsc-cont div span {
    color: #009A34;
    font-size: 1rem;
    font-weight: 500;
    font-family: Public Sans;
}


/* mid reminder , sms reminder */
.mid-rsc {
    display: flex;
    justify-content: space-around;
    background-color: #FFF;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
    max-width: 120px;
}

.mid-rsc div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.mid-rsc span {
    color: #898989;
    font-size: 1rem;
    font-weight: 500;
    font-family: Roboto;
}

.mid-rsc img {
    height: 40px;
    width: 40px;
}


/* transaction container */
.bottom-rsc {
    margin-top: 10px;
}

.rsc-tran-cont {
    background-color: #FFF;
    border-radius: 10px;
}

.rsc-ttran-header {
    padding: 15px;
    box-sizing: border-box;
    border-bottom: 1px solid #F1F1F1;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.rsc-ttran-header h6 {
    color: #111111;
    font-size: 1rem;
    font-weight: 500;
    font-family: Public Sans;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
}

.rsc-tran-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

.rsc-tran-bottom h6 {
    color: #111111;
    font-size: 1rem;
    font-weight: 500;
    font-family: Public Sans;
}

.rsc-tran-bottom span {
    color: #898989;
    font-size: 1rem;
    font-weight: 500;
    font-family: Public Sans;
}

.rsc-tran-bottom p {
    color: #212429;
    font-size: 1rem;
    font-weight: 500;
    font-family: Public Sans;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* bottom stock in stock out btn */
.rsc-btn-cont {
    display: flex;
    gap: 5px;
    margin-top: 20px;
}

.rsc-got-btn {
    border-radius: 10px;
    background: #009A34;
    display: flex;
    width: 50%;
    height: 50px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
}

.rsc-got-btn:hover {
    opacity: 0.7;
}

.rsc-gave-btn {
    border-radius: 10px;
    background: #F03D3E;
    display: flex;
    width: 50%;
    height: 50px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
}

.rsc-gave-btn:hover {
    opacity: 0.7;
}


/* popup design */
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    /* background-color: #76bb8d; */
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #009A34;
}

input:focus+.slider {
    box-shadow: 0 0 1px #009A34;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


/* jksdjkksd */

.sc-popup-switch-label{
    display: flex;
    flex-wrap: wrap;
}

.sc-popup-toppay-cont {
    border-radius: 10px;
    /* border: 1px solid var(--a-6-a-9-ac, rgba(166, 169, 172, 0.70)); */
    background: #FFF;
    color: #009A34;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    outline: none;
    height: 48px;
    margin-bottom: 15px;
    padding-left: 10px;
    box-sizing: border-box;
    width: 100%;
    /* border: none; */
    outline: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
}

.sc-popup-top-input-cont input {
    border-radius: 10px;
    /* border: 1px solid var(--a-6-a-9-ac, rgba(166, 169, 172, 0.70)); */
    background: #F1F1F1;
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    outline: none;
    height: 45px;
    margin-bottom: 15px;
    padding-left: 10px;
    box-sizing: border-box;
    width: 100%;
    border: none;
    outline: none;
}

.sc-popup-pm-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sc-popup-pm-cont label {
    border-radius: 10px;
    color: #212429;
    font-family: Public Sans;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
}


/* select and option */
.sc-popup-pm-cont label select {
    border-radius: 10px;
    border: none;
    outline: none;
    background: #F1F1F1;
    color: #212429;
    font-family: Public Sans;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    outline: none;
    height: 45px;
    margin-bottom: 15px;
}

/* Style for the dropdown arrow */
.sc-popup-pm-cont label select:after {
    content: '\25BC';
    /* Unicode character for down arrow */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

/* Style for each option in the dropdown */
.sc-popup-pm-cont label select option {
    padding: 40px;
}

.sc-popup-date-label input {
    border-radius: 10px;
    /* border: 1px solid var(--a-6-a-9-ac, rgba(166, 169, 172, 0.70)); */
    background: #F1F1F1;
    color: #212429;
    font-family: Public Sans;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    outline: none;
    height: 45px;
    margin-bottom: 15px;
    padding: 0 12px;
    box-sizing: border-box;
    width: 100%;
    border: none;
    outline: none;
}

.sc-popup-save-btn {
    font-family: Public Sans;
    text-align: center;
    width: 100%;
    height: 50px;
    color: #FFF;
    border-radius: 10px;
    background-color: #011135;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}

.sc-popup-save-btn:hover {
    opacity: 0.7;
}

@media screen and (max-width: 768px) {

    .suppliercard-cont {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    /* left part */
    .left-sc {
        width: 100%;
        height: 100%;
    }

    .lsup-card-loop {
        padding: 12px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: auto;
        height: 60vh;
    }

    /* right container */
    .right-sc {
        width: 100%;
        height: 100%;
        display: flex;
        background-color: #F1F1F1;
    }

    .tright-rsc-cont {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
    }

}