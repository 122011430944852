.servicet-cont {
    display: flex;
    width: 100%;
}

.right-service {
    width: 100%;
}

.rservice-home-cont {
    width: 100%;
}

.rservice-home-img-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.rservice-home-img-cont img {
    width: 30%;
    min-width: 200px;
}

.rservice-home-text-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
}

.rservice-home-text-cont h1 {
    color: #111111;
    font-size: 2rem;
    font-weight: 500;
    font-family: Roboto;
}

.rservice-home-text-cont h3 {
    color: #898989;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: Roboto;
}

.rservice-home-text-cont button {
    border-radius: 10px;
    background: #011135;
    display: flex;
    width: 150px;
    height: 50px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
}

.rservice-home-text-cont button:hover {
    opacity: 0.7;
}


/* service popup */
.serform-single-cont label input[type="checkbox"] {
    width: 20px;
    height: 20px;
}

.serform-single-cont label input[type="checkbox"]:checked {
    background-color: #011135;
    accent-color: #011135;
}

.ser-popup-chek-cont label {
    display: flex;
    flex-direction: row !important;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
}

.serform-single-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    gap: 5px;
}

.serform-single-cont label {
    display: flex;
    flex-direction: column;
    color: #898989;
    font-family: Public Sans;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    width: 100%;
}

.serform-single-cont input {
    border-radius: 10px;
    border: 1px solid #F1F1F1;
    background: #FFF;
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    outline: none;
    height: 45px;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
}

/* select and option */
.serform-single-cont label select {
    border-radius: 10px;
    border: 1px solid #F1F1F1;
    background: #FFF;
    color: #898989;
    font-family: Public Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    outline: none;
    height: 45px;
}

/* Style for the dropdown arrow */
.serform-single-cont label select:after {
    content: '\25BC';
    /* Unicode character for down arrow */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

/* Style for each option in the dropdown */
.serform-single-cont label select option {
    padding: 40px;
}

.serform-single-cont button {
    font-family: Public Sans;
    text-align: center;
    width: 100%;
    height: 50px;
    color: #FFF;
    border-radius: 10px;
    background-color: #011135;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}

.serform-single-cont button:hover {
    opacity: 0.7;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .servicet-cont {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {

    .servicet-cont {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .serform-single-cont {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 10px;
        gap: 5px;
    }

}