.explore {
    background-color: #DCF5FF;
}

.topexplore {
    display: flex;
    padding: 5%;
    box-sizing: border-box;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 30px;
    width: 100%;
    gap: 5%;
}

.ltopexplore {
    width: 50%;
}

.ltopexplore h5 {
    color: var(--Black, #011135);
    text-align: start;
    font-family: Inter;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
}

.ltopexplore h3 {
    color: var(--Blue, #011135);
    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    text-align: start;
}

.ltopexplore h3 span {
    color: #0090BD;
}

.rtopexplore-btn-cont {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.ltexp-signup-btn {
    display: flex;
    width: 120px;
    height: 45px;
    padding: 12px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 2px solid var(--Blue, #011135);
    color: var(--Black, #212429);
    text-align: center;
    box-sizing: border-box;
    font-family: Public Sans;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: transparent;
    cursor: pointer;
}

.ltexp-login-btn {
    display: flex;
    width: 120px;
    height: 45px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid #011135;
    background: var(--Blue, #011135);
    color: var(--Highlight, #FFF);
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    font-family: Public Sans;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.ltexp-login-btn:hover {
    opacity: 0.8;
}

.bottom-explore {
    display: flex;
    justify-content: center;
}

.bottom-exp-cont {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    background-color: #B2E6FC;
    border-radius: 30px 30px 0 0;
    padding: 24px;
    box-sizing: border-box;
}

.bec-img {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bec-img img {
    width: 100%;
    max-width: 500px;
}

.bec-text {
    width: 60%;
}

.bec-text h3 {
    color: var(--Blue, #011135);
    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    text-align: start;
}

.bec-text h3 span {
    color: #0090BD;
}

@media screen and (max-width:768px) {
    .topexplore {
        display: flex;
        flex-direction: column;
        padding: 5%;
        box-sizing: border-box;
        justify-content: space-between;
        box-sizing: border-box;
        border-radius: 30px;
        width: 100%;
        gap: 5%;
    }

    .ltopexplore {
        width: 100%;
    }

    .bottom-exp-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 1024px;
        background-color: #B2E6FC;
        border-radius: 30px 30px 0 0;
        padding: 16px;
        box-sizing: border-box;
    }

    .bec-img {
        width: 100%;
    }

    .bec-img img {
        width: 100%;
    }

    .bec-text {
        width: 100%;
    }
}