.profile-cont {
    display: flex;
}

.right-profile {
    width: 100%;
    background-color: #F1F1F1;
    display: flex;
    flex-direction: column;
}

.right-profile-header {
    border-bottom: 1px solid #ABC;
    padding: 10px;
    box-sizing: border-box;
}

.right-profile-header h3 {
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
}

.bottom-rprofile {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px;
    box-sizing: border-box;
}

.rleft-profile {
    width: 100%;
    max-width: 250px;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.rleft-profile-card {
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
}


.rright-profile {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.rright-profile-card {
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* height: 100%; */
    width: 100%;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.rright-profile-top-btn-cont {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.rprofile-head-text {
    color: #7B7B7B;
    font-family: Public Sans;
    font-size: 1rem;
    font-weight: 500;
}

.rprofile-head-text-name {
    color: #212429;
    font-family: Public Sans;
    font-size: 1rem;
    font-weight: 500;
}

.rright-profile-top-btn-cont button {
    font-family: Roboto;
    text-align: center;
    width: 160px;
    height: 50px;
    color: #FFF;
    border-radius: 10px;
    background-color: #011135;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}

.rright-profile-top-btn-cont button:hover {
    opacity: 0.7;
}

.rright-profile-bd-cont {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.rright-profile-bd-cont div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.rl-profile-logo-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rl-profile-logo-cont h3 {
    color: #080808;
    background-color: #9CE7FF;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    font-family: Public Sans;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rl-profile-logo-cont h4 {
    color: #080808;
    font-family: Public Sans;
    font-size: 1.1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rl-profile-detail-cont {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding-top: 6%;
    box-sizing: border-box;
}

.rl-profile-detail-cont div {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start;
    align-items: flex-start; */
    width: 100%;
    padding-top: 6%;
    box-sizing: border-box;
}


.rr-profilecard-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px; */
    box-sizing: border-box;
    width: 100%;
    align-items: center;
}

.rr-profilecard-table table {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.rr-profilecard-table table tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 10px;
    box-sizing: border-box;
}

.rr-profilecard-table table tr td {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}


@media screen and (min-width: 640px) and (max-width: 1024px) {

    .profile-cont {
        display: flex;
        flex-direction: column;
    }

}


@media screen and (max-width: 640px) {

    .profile-cont {
        display: flex;
        flex-direction: column;
    }

    .bottom-rprofile {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        box-sizing: border-box;
    }

    .rleft-profile {
        width: 100%;
        max-width: none;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
    }

    .rright-profile-top-btn-cont button {
        font-family: Roboto;
        text-align: center;
        width: 120px;
        height: 40px;
        color: #FFF;
        border-radius: 10px;
        background-color: #011135;
        border: none;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
    }



}