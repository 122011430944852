.aser-cont {
    display: flex;
    width: 100%;
    height: 100%;
}

/* left part */
.left-addser {
    width: 50%;
    height: 100%;
}

.left-addser-cont {
    width: 100%;
    background-color: #FFF;
}

/* search container styles */
/* search input  */
.laser-search-cont {
    padding: 10px;
    box-sizing: border-box;
}

.laser-search-box {
    color: var(--080808, #0F0F0F);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    text-align: start;
    height: 45px;
    display: flex;
    align-items: center;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 1px 4px 15.2px 0px rgba(0, 0, 0, 0.09);
    width: 100%;
    padding: 0 10px;
    position: relative;
    box-sizing: border-box;
}

.laser-search-box input {
    border-radius: 14px;
    background: #FFF;
    /* box-shadow: 1px 4px 15.2px 0px rgba(0, 0, 0, 0.09); */
    height: 45px;
    padding: 0 10px;
    outline: none;
    box-sizing: border-box;
    color: var(--080808, #0F0F0F);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    margin-top: 0;
    width: 100%;
    border: none;
}

.laser-search-box span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666262;
    position: relative;
}

/* single customer container */
.laser-card-loop {
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    height: 65vh;
}

.laser-card {
    display: flex;
    justify-content: space-between;
    border: 1px solid #F1F1F1;
    border-radius: 10px;

}

.leftaser-card {
    display: flex;
    padding: 5px;
    box-sizing: border-box;
}

.left-aser-logo {
    color: #111111;
    font-size: 1rem;
    font-weight: 500;
    font-family: Roboto;
    background-color: #FFD28F;
    border-radius: 10px;
    height: 40px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.laser-edit-btn {
    border: none;
    background-color: transparent;
    font-size: 0.8rem;
    color: #898989;
    font-weight: 500;
    font-family: Roboto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.laser-lp-cont {
    padding-left: 5px;
    box-sizing: border-box;
}

.laser-lp-cont h6 {
    color: #111111;
    font-size: 1rem;
    font-weight: 500;
    font-family: Roboto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
}

.laser-lp-cont p {
    color: #ACB5BD;
    font-size: 0.9rem;
    font-weight: 400;
    font-family: Roboto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
    gap: 2px;
}


.left-right-aser-card {
    display: flex;
    flex-direction: column;
}

.laser-service-text-cont span {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: #011135;
    border-radius: 0 10px 0 10px;
    color: #FFF;
    font-size: 0.9rem;
    font-weight: 400;
    font-family: Roboto;
    padding: 5px 10px;
    box-sizing: border-box;
}

.lasc-add-service-btn {
    display: flex;
    justify-content: flex-end;
    padding: 12px;
    box-sizing: border-box;
}

.lasc-add-service-btn button {
    border-radius: 10px;
    background: #011135;
    display: flex;
    width: 120px;
    height: 44px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
}

.lasc-add-service-btn button:hover {
    opacity: 0.7;
}

/* right container */
.right-aser {
    width: 50%;
    height: 100%;
    height: 100vh;
    display: flex;
    background-color: #F1F1F1;
}

.raser-cont {
    width: 100%;
    height: 100%;
    background-color: #F1F1F1;
    padding: 15px 10px;
    box-sizing: border-box;
}

/* right top container */
.top-raser {
    margin-bottom: 10px;
}

.top-raser-cont {
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-sizing: border-box;
}

.tleft-raser-cont {
    display: flex;
    gap: 5px;
    padding: 5px;
    box-sizing: border-box;
}

.tleft-raser-cont h5 {
    color: #111111;
    font-size: 1.5rem;
    font-weight: 500;
    font-family: Roboto;
    background-color: #FFD28F;
    border-radius: 10px;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.tleft-raser-cont span {
    color: #111111;
    font-size: 1rem;
    font-weight: 500;
    font-family: Roboto;

}

.tright-raser-cont {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}

.tright-raser-cont button {
    background: transparent;
    display: flex;
    /* width: 50%;
    height: 50px; */
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #898989;
    text-align: center;
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    gap: 4px;
}


/* mid reminder , sms reminder */
.mid-raser {
    display: flex;
    justify-content: space-between;
    /* background-color: #FFF;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
    max-width: 120px; */
    width: 100%;
    gap: 5px;
}

.mid-raser div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #FFF;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
}

.mid-raser span {
    color: #898989;
    font-size: 1rem;
    font-weight: 500;
    font-family: Roboto;
}

.mid-raser strong {
    color: #111111;
    font-size: 1rem;
    font-weight: 500;
    font-family: Roboto;
}

.mid-raser img {
    height: 40px;
    width: 40px;
}

@media screen and (max-width: 768px) {

    .aser-cont {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    /* left part */
    .left-addser {
        width: 100%;
        height: 100%;
    }

    .laser-card-loop {
        padding: 12px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: auto;
        height: 60vh;
    }

    /* right container */
    .right-aser {
        width: 100%;
        height: 100%;
        display: flex;
        background-color: #F1F1F1;
    }

    .tright-raser-cont {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 5px;
    }
}