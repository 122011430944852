/* .footer {
    padding: 0 2% 2% 2%;
    box-sizing: border-box;
} */

.footer-cont {
    background-image: url("../../../public/assests/footer.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: calc(100vh - 64px); */
    display: flex;
    flex-direction: column;
    padding: 5%;
    box-sizing: border-box;
    justify-content: space-between;
    /* background: #011135; */
}

.ftop {
    display: flex;
    justify-content: space-between;
}

.fleft_top {
    width: 50%
}

.fleft_top-img-cont {
    width: 50%;
}

.fleft_top-img-cont img {
    width: 100%;
    max-width: 200px;
}

.fleft_top h5 {
    font-size: 1.2rem;
    font-weight: 500;
    color: rgba(238, 255, 250, 0.72);
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 25.6px;
}

/* .fmid_top {
    color: #fff;
} */

.fmid_top h4 {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 15px;
    color: #FFF;
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 600;
    line-height: 31.2px;
}

.fmid_top_list {
    display: flex;
    flex-direction: column;
}

.fmid_top_list span {
    font-size: 1rem;
    color: rgba(238, 255, 250, 0.72);
    font-weight: 500;
    /* color: #EEFFFA; */
    margin-bottom: 10px;
    cursor: pointer;
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

/* .fright_top {
    color: #fff;
} */

.fright_top h4 {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 15px;
    color: #FFF;
    font-family: "Public Sans";
    font-style: normal;
    font-weight: 600;
}

.fright_top_list {
    display: flex;
    flex-direction: column;
}

.fright_top_list_cont {
    display: flex;
    margin-bottom: 15px;
}

.fright_top_list_cont button {
    border: none;
    box-sizing: border-box;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.12);
    color: #0090BD;
    width: 45px;
    height: 45px;
    padding: 12px 10.669px 11px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.fright_top_list_text {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}

.fright_top_list_text span {
    color: #D9D9D9;
    font-family: "Public Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}


.fbottom {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

/* .fbottom_left {
    color: #fff;
} */

.fbottom_left h2 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 0;
    color: #FFF;
    font-family: "Public Sans";
    font-style: normal;
    line-height: normal;
}

.fbottom_left_input_cont {
    display: flex;
    margin-top: 15px;
}

.fbottom_left_input_cont input {
    width: 100%;
    padding: 10px 5px 10px 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #F4FFFC;
    background-color: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    color: rgba(244, 255, 252, 0.62);
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.fbottom_left_input_cont button {
    border: none;
    color: #fff;
    background-color: #0090BD;
    padding: 5px 6px;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 8px;
    transform: translateX(-40px);
    font-size: 1rem;
    width: 40px;
    height: 38px;
    flex-shrink: 0;
}

.fbottom_right {
    color: #fff;
    gap: 10px;
    display: flex;
    align-items: flex-end;
}

.fbottom_right span {
    border-radius: 8px;
    border: 1px solid #FFF;
    padding: 8px 10px;
    box-sizing: border-box;
    font-size: 1rem;
    display: flex;
    width: 40px;
    height: 40px;
    /* padding: 14.39px 14.104px 14.41px 14.39px; */
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}


@media screen and (min-width:778px) and (max-width:992px) {
    .footer {
        padding: 0 2% 0 2%;
        box-sizing: border-box;
    }

    .footer-cont {
        background-image: url("../../../public/assests/footer.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        /* height: calc(100vh - 64px); */
        display: flex;
        flex-direction: column;
        padding: 8%;
        box-sizing: border-box;
        justify-content: space-between;
    }

    .ftop {
        display: flex;
        justify-content: space-between;
    }

    .fleft_top {
        color: #fff;
        width: 50%
    }

    .fleft_top-img-cont {
        width: 50%;
    }

    .fleft_top-img-cont img {
        width: 100%;
    }

    .fleft_top h5 {
        font-size: 1.2rem;
        font-weight: 500;
        color: #fff;
    }

    .fmid_top {
        color: #fff;
    }

    .fmid_top h4 {
        font-size: 1.2rem;
        font-weight: 500;
        color: #fff;
        margin-bottom: 15px;
    }

    .fmid_top_list {
        display: flex;
        flex-direction: column;
    }

    .fmid_top_list span {
        font-size: 1rem;
        font-weight: 500;
        color: #EEFFFA;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .fright_top {
        color: #fff;
    }

    .fright_top h4 {
        font-size: 1.4rem;
        font-weight: 500;
        color: #fff;
        margin-bottom: 15px;
    }

    .fright_top_list {
        display: flex;
        flex-direction: column;
    }

    .fright_top_list_cont {
        display: flex;
        margin-bottom: 15px;
    }

    .fright_top_list_cont button {
        border: none;
        box-sizing: border-box;
        border-radius: 6px;
        background: rgba(255, 255, 255, 0.12);
        color: #0090BD;
        width: 45px;
        height: 45px;
        padding: 12px 10.669px 11px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
    }

    .fright_top_list_text {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
    }


    .fbottom {
        display: flex;
        justify-content: space-between;
    }

    .fbottom_left {
        color: #fff;
    }

    .fbottom_left h2 {
        color: #fff;
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .fbottom_left_input_cont {
        display: flex;
    }

    .fbottom_left_input_cont input {
        width: 100%;
        padding: 10px 5px 10px 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #F4FFFC;
        background-color: transparent;
        border-top: none;
        border-left: none;
        border-right: none;
    }

    .fbottom_left_input_cont button {
        border: none;
        color: #fff;
        background-color: #0090BD;
        padding: 10px 12px;
        box-sizing: border-box;
        border-radius: 2px;
        cursor: pointer;
        margin-bottom: 8px;
        transform: translateX(-40px);
    }

    .fbottom_right {
        color: #fff;
        gap: 10px;
        display: flex;
        align-items: center;
    }

    .fbottom_right span {
        border: 1px solid #fff;
        padding: 5px 6px;
        box-sizing: border-box;
        border-radius: 5px;
    }
}



@media screen and (max-width:768px) {
    .footer {
        padding: 0;
        box-sizing: border-box;
    }

    .footer-cont {
        background-image: url("../../../public/assests/footer.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        /* height: calc(100vh - 64px); */
        display: flex;
        flex-direction: column;
        padding: 0;
        box-sizing: border-box;
        justify-content: space-between;
        background: #011135;
    }

    .ftop {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        background: #011135;
        padding: 20px;
        box-sizing: border-box;
    }

    .fleft_top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        width: 100%
    }

    .fleft_top h5 {
        font-size: 1.1rem;
        font-weight: 500;
        color: rgba(238, 255, 250, 0.72);
        font-family: "Public Sans";
        font-style: normal;
        font-weight: 400;
        line-height: 25.6px;
        text-align: center;
    }

    .fmid_top_list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .fright_top {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
    }


    .fbottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        background: #011135;
        padding: 30px;
        box-sizing: border-box;
        margin: 0;
    }

    .fbottom_left h2 {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0;
        color: #FFF;
        font-family: "Public Sans";
        font-style: normal;
        line-height: normal;
        text-align: center;
    }

    .fbottom_left_input_cont {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;

    }

    .fbottom_left_input_cont input {
        width: 100%;
        padding: 10px 5px 10px 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #F4FFFC;
        background-color: transparent;
        border-top: none;
        border-left: none;
        border-right: none;
        transform: translateX(20px);
    }

}