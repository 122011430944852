.banner {
    /* height: 100vh; */
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #DCF5FF;
}

.banner-cont {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.right-banner {
    width: 60%;
    margin: 0;
}

.right-banner img {
    width: 100%;
    height: 100%;
}

.left-banner {
    width: 40%;
    padding: 5%;
    box-sizing: border-box;
}

.banner-head-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
}

.banner-head-cont h1 {
    color: #0E0E0E;
    text-align: start;
    font-family: Inter;
    font-size: 2.6rem;
    font-style: normal;
    font-weight: 700;
    margin: 0;
}

.banner-head-cont h3 {
    color: #000000;
    text-align: start;
    font-family: Inter;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 16px;
}

.banner-head-cont button {
    color: #FFF;
    border: none;
    padding: 12px;
    background-color: rgba(1, 17, 53, 1);
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    font-family: Inter;
}

.banner-head-cont button:hover {
    opacity: 0.8;
}

@media screen and (max-width:768px) {

    .banner-cont {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .right-banner {
        width: 100%;
    }

    .right-banner img {
        width: 100%;
    }

    .left-banner {
        width: 100%;
        padding: 3%;
        box-sizing: border-box;
    }

}