.productheader-cont {
    padding: 10px;
    border-bottom: 1px solid #ACB5BD;
    box-sizing: border-box;
}

.prohead-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #212429;
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 400;
    padding: 5px 10px;
    box-sizing: border-box;
}

.prohead-btn.active {
    border-radius: 10px;
    background: #011135;
    color: #FFFFFF;
}