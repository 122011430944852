.FAQ-cont{
  padding: 5%;
  box-sizing: border-box;
  background-color: #DCF5FF;
}

.FAQ {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 40px;
  // background: #011135;
  // border-radius: 20px;
  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    // padding: 5% 10%;
    // box-sizing: border-box;
    h1 {
      font-weight: 600;
      color: #000000;
      font-family: Public Sans;
      margin: 0;
      span {
        color: rgba(0, 144, 189, 1);
        margin: 0;
      }
    }
    h3 {
      font-weight: 500;
      color: #000000;
      font-family: Public Sans;
      margin: 0;
    }
    .accordian {
      display: flex;
      flex-direction: column;
      gap: 15px;
      border: none;
      .acc-child {
        background-color: #F9F9F9;
        // box-shadow: 6px 4px 33px 0px rgba(0, 0, 0, 0.224);
        border-radius: 10px;
      }
    }
  }
  .right {
    flex: 1;
    padding-top: 30px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    img {
      width: 100%;
      position: static;
    }
  }
}

.faq-right-mob-img{
  display: none;
}

@media screen and (max-width:"768px") {
  .right {
    flex: 1;
    padding-top: 30px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
  }
  .faq-right-comp-img{
    display: none;
  }
  .faq-right-mob-img{
    display: block;
    width: 100%;
  }
}