.invoice-cont {
    display: flex;
    width: 100%;
}

.right-invoice {
    width: 100%;
}

.right-invoice-home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rinvoice-home-header {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #ACB5BD;
    margin-bottom: 20px;
}

.rinvoice-home-img-cont {
    /* background-color: aqua; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rinvoice-home-img-cont img {
    width: 30%;
    min-width: 200px;
}

.right-invoice-home h1 {
    text-align: center;
    font-size: 2rem;
    font-family: 'Roboto';
    font-weight: 500;
    color: #212429;
    margin-bottom: 0;
}

.right-invoice-home h4 {
    color: #898989;
    text-align: center;
    font-size: 1.2rem;
    font-family: Public Sans;
    font-weight: 400;
    margin-bottom: 10px;

}

.rinvoice-home-btn {
    font-family: Roboto;
    text-align: center;
    width: 160px;
    height: 50px;
    color: #FFF;
    border-radius: 10px;
    background-color: #011135;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
}

.rinvoice-home-btn:hover {
    opacity: 0.7;
}

.invoice-link {
    text-decoration: none;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .invoice-cont {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .invoice-cont {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

}